import { BE_TYPE, backendServiceApi } from 'common/api/backend-service-api';
import { dispatchMessageConfiguration, mapSysIdAsTags } from 'common/api/utils';
import { addError, addSuccess } from 'common/slice/message';
import {
  createExtractedFilesFailedMessage,
  deleteDocumentFailedMessage,
  deleteDocumentsFailedMessage,
  fetchDocumentFailedMessage,
  fetchDocumentsFailedMessage,
  getDocumentHistoryFailedMessage,
  getRevisionsFailedMessage,
  getStatisticsFailedMessage,
  updateAllMetadataFailedMessage,
  updateAllVisibilityFailedMessage,
  updateDocumentFailedMessage,
  updateDocumentsFailedMessage,
  updateRevisionFailedMessage,
  updateVisibilityFailedMessage,
  uploadFailedMessage,
  exportDocumentListFailedMessage,
  setLinksFailedMessage,
  documentDownloadFailedMessage,
  documentDisplayFailedMessage,
} from 'common/message/error-messages';
import {
  updateAllMetadataSuccessMessage,
  updateAllVisibilitySuccessMessage,
  updateDocumentSuccessMessage,
  updateVisibilitySuccessMessage,
  uploadStartSuccessMessage,
} from 'common/message/success-messages';
import { getExtension, sliceExtension, getRevisionDownloadName } from 'utils/edm/document';
import { formatVisibility, getFlatSort } from 'utils/edm/utils';
import FileSaver from 'file-saver';
import { toDate as toDateService } from 'utils/common/date';
import { activeList, formatDoc, formatForRequest } from 'utils/metadata/metadata';
import {
  removeUploadLoading,
  setStartUpload,
  setUploadError,
  uploadRevisionFile,
  onUploadRevisionSuccess,
} from 'common/upload/actions';

const documentApi = backendServiceApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getDocuments: build.query({
      query: ({
        projectSysId,
        from,
        size,
        archived,
        sort,
        searchValue,
        fromDate,
        toDate,
        emitters,
        approvers,
        contributors,
        statuses,
        extensions,
        groups,
        metadata,
        folders,
      }) => ({
        url: `/project/${projectSysId}/documents`,
        method: 'POST',
        body: {
          from,
          size,
          archived,
          sort: getFlatSort(sort),
          searchValue,
          fromDate,
          toDate,
          emitters,
          approvers,
          contributors,
          extensions,
          statuses,
          visibility: formatVisibility(groups),
          metadata: formatForRequest(metadata),
          folders,
        },
      }),
      ...dispatchMessageConfiguration({
        error: () => ({
          message: fetchDocumentsFailedMessage,
        }),
      }),
      transformResponse: (response) => {
        return {
          total: response.total,
          documents: response.results.map((document) => ({
            ...document,
            metadatas: Object.keys(document.metadatas).reduce(
              (acc, key) => ({ ...acc, [key]: document.metadatas[key][0] }),
              {}
            ),
          })),
        };
      },
      providesTags: (response) => mapSysIdAsTags(response?.documents, BE_TYPE.DOCUMENT),
    }),
    getDocument: build.query({
      query: ({ projectSysId, documentSysId }) => ({
        url: `/project/${projectSysId}/document/${documentSysId}`,
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: fetchDocumentFailedMessage }) }),
      transformResponse: (document) => {
        return {
          ...document,
          metadatas: Object.keys(document.metadatas).reduce(
            (acc, key) => ({ ...acc, [key]: document.metadatas[key][0] }),
            {}
          ),
        };
      },
      providesTags: (response, meta, arg) => [{ type: BE_TYPE.DOCUMENT, id: arg.documentSysId }],
    }),
    getDocumentRevisions: build.query({
      query: ({ projectSysId, documentSysId, from, size, version }) => ({
        url: `/project/${projectSysId}/document/${documentSysId}/revisions`,
        params: { from, size, version },
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: getRevisionsFailedMessage }) }),
      transformResponse: (response, meta, arg) => {
        return {
          ...response,
          offset: arg.from,
          documentSysId: arg.documentSysId,
        };
      },
      providesTags: (response) => [
        ...mapSysIdAsTags(response?.revisions, BE_TYPE.REVISION),
        { type: BE_TYPE.DOCUMENT, id: response?.documentSysId },
      ],
    }),
    getDocumentStats: build.query({
      query: ({ projectSysId }) => ({
        url: `/project/${projectSysId}/document/stats`,
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: getStatisticsFailedMessage }) }),
      keepUnusedDataFor: 1,
    }),
    getDocumentEvents: build.query({
      query: ({ projectSysId, refSysId, from, size }) => ({
        url: `/project/${projectSysId}/events`,
        params: {
          resource: 'document',
          refSysId,
          from,
          size,
        },
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: getDocumentHistoryFailedMessage }) }),
      providesTags: (response, meta, arg) => [
        { type: BE_TYPE.DOCUMENT, id: arg.refSysId },
        { type: BE_TYPE.EVENT, id: 'LIST' },
      ],
    }),
    getDetectMetadata: build.query({
      query: ({ projectSysId, files }) => ({
        url: `/project/${projectSysId}/document/detect?type=1`,
        method: 'POST',
        body: files,
      }),
      keepUnusedDataFor: 1,
      ...dispatchMessageConfiguration({ error: () => ({ message: createExtractedFilesFailedMessage }) }),
      transformResponse(response, meta, arg) {
        if (!response || response.length === 0) {
          return { extractedFiles: [] };
        }
        const formattedExtractedFiles = response.map(({ isValid, name, results }) => {
          const formattedMetadata = results.reduce((acc, { code: category, detected }) => {
            const activeMetadata = activeList(arg.categories[category]?.children || []);
            return {
              ...acc,
              [category]: (() => {
                if (detected) {
                  return { originalPath: detected.path, code: detected.code, path: detected.path };
                }
                return activeMetadata.length === 1
                  ? {
                      code: activeMetadata[0].path,
                      path: activeMetadata[0].path,
                    }
                  : {};
              })(),
            };
          }, {});
          return {
            name,
            fileName: sliceExtension(name),
            visibility: {
              isPrivate: false,
              groups: [],
              groupNames: [],
              flat: '+',
            },
            ...formattedMetadata,
            isValid:
              isValid ||
              ['PHS', 'EMT', 'SPE', 'BZO', 'NIV', 'LOT', 'TYP'].every((category) => formattedMetadata[category].code),
          };
        });
        return {
          extractedFiles: formattedExtractedFiles,
        };
      },
    }),
    // MUTATION
    createDocumentHolder: build.mutation({
      query: ({ projectSysId, data }) => ({
        url: `/project/${projectSysId}/document`,
        method: 'POST',
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          data.map((doc) => {
            if (!doc.success) {
              dispatch(
                addError({ error: new Error(doc.document.error), message: uploadFailedMessage(doc.document.title) })
              );
            }

            return doc;
          });
        } catch (err) {
          dispatch(addError({ error: err, message: updateDocumentFailedMessage }));
        }
      },
      invalidatesTags: () => [{ type: BE_TYPE.DOCUMENT, id: 'LIST' }],
    }),
    updateDocument: build.mutation({
      query: ({ projectSysId, documentSysId, data }) => ({
        url: `/project/${projectSysId}/document/${documentSysId}`,
        method: 'PUT',
        body: data,
      }),
      ...dispatchMessageConfiguration({
        error: () => ({ message: updateDocumentFailedMessage }),
        success: () => ({ message: updateDocumentSuccessMessage }),
      }),
      invalidatesTags: (result, error, args) =>
        error
          ? []
          : [
              { type: BE_TYPE.DOCUMENT, id: args.documentSysId },
              { type: BE_TYPE.DOCUMENT, id: 'LIST' },
            ],
    }),
    updateDocumentVisibility: build.mutation({
      query: ({ projectSysId, documentSysId, data }) => ({
        url: `/project/${projectSysId}/document/${documentSysId}/visibility`,
        method: 'PUT',
        body: data,
      }),
      ...dispatchMessageConfiguration({
        error: () => ({ message: updateVisibilityFailedMessage }),
        success: () => ({ message: updateVisibilitySuccessMessage }),
      }),
      invalidatesTags: (result, error, args) =>
        error
          ? []
          : [
              { type: BE_TYPE.DOCUMENT, id: args.documentSysId },
              { type: BE_TYPE.DOCUMENT, id: 'LIST' },
            ],
    }),
    deleteDocuments: build.mutation({
      query: ({ projectSysId, data }) => ({
        url: `project/${projectSysId}/document`,
        method: 'DELETE',
        body: data,
        responseHandler: 'text',
      }),
      ...dispatchMessageConfiguration({
        error: (response) =>
          [...response.data.sysId].length > 1
            ? { message: deleteDocumentsFailedMessage }
            : { message: deleteDocumentFailedMessage },
      }),
      invalidatesTags: (result, error) => (error ? [] : [{ type: BE_TYPE.DOCUMENT, id: 'LIST' }]),
    }),
    updateRevision: build.mutation({
      query: ({ projectSysId, documentSysId, revisionSysId, data }) => ({
        url: `/project/${projectSysId}/document/${documentSysId}/revision/${revisionSysId}`,
        method: 'PUT',
        body: data,
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: updateRevisionFailedMessage }) }),
      invalidatesTags: (result, error, args) =>
        error
          ? []
          : [
              { type: BE_TYPE.DOCUMENT, id: args.documentSysId },
              { type: BE_TYPE.DOCUMENT, id: 'LIST' },
              { type: BE_TYPE.REVISION, id: args.revisionSysId },
            ],
    }),
    updateDocuments: build.mutation({
      query: ({ projectSysId, data }) => ({
        url: `/project/${projectSysId}/document`,
        method: 'PUT',
        body: data,
        responseHandler: 'text',
      }),
      ...dispatchMessageConfiguration({
        error: (response) =>
          [...response.data.sysId].length > 1
            ? { message: updateDocumentsFailedMessage }
            : { message: updateDocumentFailedMessage },
      }),
      invalidatesTags: (result, error, args) =>
        error
          ? []
          : [
              ...args.data.sysId.map((sysId) => ({ type: BE_TYPE.DOCUMENT, id: sysId })),
              { type: BE_TYPE.DOCUMENT, id: 'LIST' },
              { type: BE_TYPE.FOLDER, id: 'LIST' },
            ],
    }),
    updateDocumentsMetadata: build.mutation({
      query: ({ projectSysId, data }) => ({
        url: `/project/${projectSysId}/document`,
        method: 'PUT',
        body: data,
        responseHandler: 'text',
      }),
      ...dispatchMessageConfiguration({
        error: () => ({ message: updateAllMetadataFailedMessage }),
        success: () => ({ message: updateAllMetadataSuccessMessage }),
      }),
      invalidatesTags: (result, error, args) =>
        error
          ? []
          : [
              ...args.data.sysId.map((sysId) => ({ type: BE_TYPE.DOCUMENT, id: sysId })),
              { type: BE_TYPE.DOCUMENT, id: 'LIST' },
            ],
    }),
    updateDocumentsVisibility: build.mutation({
      query: ({ projectSysId, data }) => ({
        url: `/project/${projectSysId}/document`,
        method: 'PUT',
        body: data,
        responseHandler: 'text',
      }),
      ...dispatchMessageConfiguration({
        error: () => ({ message: updateAllVisibilityFailedMessage }),
        success: () => ({ message: updateAllVisibilitySuccessMessage }),
      }),
      invalidatesTags: (result, error, args) =>
        error
          ? []
          : [
              ...args.data.sysId.map((sysId) => ({ type: BE_TYPE.DOCUMENT, id: sysId })),
              { type: BE_TYPE.DOCUMENT, id: 'LIST' },
            ],
    }),
    uploadRevision: build.mutation({
      async queryFn(args, { dispatch }) {
        const { data, documentSysId, projectSysId } = args;
        dispatch(setStartUpload(projectSysId, data.key, data.fileName));
        const success = await dispatch(uploadRevisionFile(projectSysId, documentSysId, data, true));
        if (success) {
          dispatch(onUploadRevisionSuccess(projectSysId, documentSysId));
        }

        dispatch(removeUploadLoading(projectSysId));

        return { data: {} };
      },
    }),
    uploadDocuments: build.mutation({
      async queryFn(args, { dispatch }, _extraOptions, baseQuery) {
        const { data, projectSysId } = args;

        dispatch(addSuccess({ message: uploadStartSuccessMessage }));

        try {
          await data
            .map((uploadFile) => {
              dispatch(setStartUpload(projectSysId, uploadFile.key, uploadFile.fileName));
              return uploadFile;
            })
            .reduce(async (accumulator, file) => {
              await accumulator
                .then(() => {
                  const uploadingFile = [
                    {
                      title: file.fileName,
                      visibility: {
                        groups: file.visibility.groups,
                        isPrivate: file.visibility.isPrivate,
                      },
                      metadata: formatDoc(file),
                      ext: getExtension(file.file.name),
                    },
                  ];

                  return baseQuery({
                    url: `/project/${projectSysId}/document`,
                    method: 'POST',
                    body: uploadingFile,
                  });
                })
                .then(async (result) => {
                  if (result.error || !result.data || !result.data[0].success) {
                    dispatch(setUploadError(projectSysId, file.key));
                    dispatch(removeUploadLoading(projectSysId));
                    return;
                  }

                  const success = await dispatch(uploadRevisionFile(projectSysId, result.data[0].document.sysId, file));
                  if (success) {
                    dispatch(onUploadRevisionSuccess(projectSysId, result.data[0].document.sysId));
                  }
                });
            }, Promise.resolve());
          dispatch(removeUploadLoading(projectSysId));
          return { data: {} };
        } catch (error) {
          return { error: { status: 500, statusText: error.message } };
        }
      },
    }),
    exportDocumentList: build.mutation({
      query: ({ projectSysId, data }) => ({
        url: `/project/${projectSysId}/document/export`,
        method: 'POST',
        body: data,
        responseHandler: (response) => response.arrayBuffer(),
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const date = toDateService();
          const blob = new Blob([data]);
          return FileSaver.saveAs(
            blob,
            `${arg.projectName}-Liste des ${arg.data.revisions ? 'révisions' : 'documents'}_DS-${date}.xlsx`
          );
        } catch (err) {
          return dispatch(addError({ error: err, message: exportDocumentListFailedMessage }));
        }
      },
    }),
    shareDocument: build.mutation({
      query: ({ projectSysId, documentSysId, data }) => ({
        url: `/project/${projectSysId}/document/${documentSysId}/share`,
        method: 'POST',
        body: data,
      }),
    }),
    updateDocumentLinks: build.mutation({
      query: ({ projectSysId, documentSysId, data }) => ({
        url: `/project/${projectSysId}/document/${documentSysId}/links`,
        method: 'PUT',
        body: data,
      }),
      ...dispatchMessageConfiguration({
        error: ({ documentTitle }) => ({ message: setLinksFailedMessage(documentTitle) }),
      }),
      invalidatesTags: (result, error, args) =>
        error
          ? []
          : [
              { type: BE_TYPE.DOCUMENT, id: args.documentSysId },
              { type: BE_TYPE.DOCUMENT, id: 'LIST' },
            ],
    }),
    downloadLastRevision: build.mutation({
      query: ({ projectSysId, documentSysId, data }) => ({
        url: `/project/${projectSysId}/document/${documentSysId}/revisions`,
        method: 'GET',
        params: data,
      }),
      async onQueryStarted({ documentTitle }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (documentTitle) {
            const a = document.createElement('a');
            a.href = data.revisions?.length ? `/${data.revisions[0].file}` : '#';
            a.download = getRevisionDownloadName(documentTitle, data.revisions[0]);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        } catch (err) {
          dispatch(
            addError({
              error: err,
              message: documentTitle ? documentDownloadFailedMessage : documentDisplayFailedMessage,
            })
          );
        }
      },
    }),
    copyDocumentTo: build.mutation({
      query: ({ projectSysId, documentSysId, data }) => ({
        url: `/project/${projectSysId}/document/${documentSysId}/copyTo`,
        method: 'POST',
        body: data,
      }),
      ...dispatchMessageConfiguration({
        error: () => ({ message: 'Erreur lors de la copie du document' }),
        success: () => ({ message: 'Le document a bien été copié' }),
      }),
      invalidatesTags: () => [{ type: BE_TYPE.DOCUMENT, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useGetDocumentQuery,
  useGetDocumentRevisionsQuery,
  useGetDocumentStatsQuery,
  useGetDocumentEventsQuery,
  useGetDocumentsFiltersQuery,
  useGetDetectMetadataQuery,
  useUpdateDocumentMutation,
  useUpdateDocumentVisibilityMutation,
  useDeleteDocumentsMutation,
  useUpdateRevisionMutation,
  useUpdateDocumentsMutation,
  useCreateDocumentHolderMutation,
  useUpdateDocumentsMetadataMutation,
  useUpdateDocumentsVisibilityMutation,
  useUploadRevisionMutation,
  useUploadDocumentsMutation,
  useExportDocumentListMutation,
  useShareDocumentMutation,
  useUpdateDocumentLinksMutation,
  useDownloadLastRevisionMutation,
  useCopyDocumentToMutation,
} = documentApi;

export default documentApi;
